import * as React from 'react'
import { useState } from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch as Routes,} from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {createTheme, CSSObject, styled, Theme, ThemeProvider,} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {PaletteMode} from '@mui/material'
import Copyright from './components/Copyright'
import ForgotPassword from './pages/auth/ForgotPassword'
import LogIn from './pages/auth/LogIn'
import SignUp from './pages/auth/SignUp'
import ConfirmEmail from './pages/auth/ConfirmEmail'
import DashboardPage from './pages/DashboardPage'
import UserDetailsPage from './pages/user/UserDetailsPage'
import AddSubscription from './pages/subscriptions/AddSubscription'
import AddTransaction from './pages/backoffice/transactions/AddTransaction'
import AddAccount from './pages/backoffice/accounts/AddAccount'
import SubscriptionsPage from './pages/subscriptions/SubscriptionsPage'
import TransactionsPage from './pages/backoffice/transactions/TransactionsPage'
import AccountsPage from './pages/backoffice/accounts/AccountsPage'
import QuotesPage from './pages/quotes/QuotesPage'
import PropertyContractsPage from './pages/properties/PropertyContractsPage'
import NotificationTooltip from './components/NotificationTooltip'
import data from './data/locale/en/data.json'
import paths from './data/paths.json'
import features from './data/features.json'
import useToken from './services/useToken'
import UserDetailsMenu from './components/UserDetailsMenu'
import userDrawer from './services/useDrawer'
import MainMenuItems from './components/MainMenuItems'
import NotificationsPage from './pages/NotificationsPage'
import PropertyDetailsPage from './pages/properties/PropertyDetailsPage'
import TerminalPage from './pages/terminal/TerminalPage'
import IntegrationsPage from './pages/integrations/IntegrationsPage'
import {DesktopOnly, MobileOnly} from './components/MobileDesktopVisibility'
import MobileBottomNav from './components/MobileBottomNav'
import ContractsPage from './pages/backoffice/contracts/ContractsPage'
import AddContract from './pages/backoffice/contracts/AddContract'
import OrganisationsPage from './pages/backoffice/organisations/OrganisationsPage'
import AddOrganisation from './pages/backoffice/organisations/AddOrganisation'
import MiniApps from './pages/MiniApps'
import AssetsPage from './pages/backoffice/assets/AssetsPage'
import PropertiesPage from './pages/properties/PropertiesPage'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const openedMixin = (theme: Theme): CSSObject => ({
    width: data.drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: data.drawerWidth,
        width: `calc(100% - ${data.drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: data.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const useStyles = makeStyles((theme: Theme) => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
}))

const queryClient = new QueryClient();

export default function App() {
    const palette = (localStorage.getItem('palette') || 'dark') as PaletteMode
    localStorage.setItem('palette', palette)
    const [theme, setTheme] = useState(() =>
        createTheme({
            palette: {
                mode: palette,
            },
        }),
    )

    const toggleDarkTheme = () => {
        let newPaletteMode =
            theme.palette.mode === 'light' ? 'dark' : ('light' as PaletteMode)
        localStorage.setItem('palette', newPaletteMode)
        setTheme(() =>
            createTheme({
                palette: {
                    mode: newPaletteMode,
                },
            }),
        )
    }

    const muiTheme = createTheme(theme)

    const classes = useStyles()

    const {drawerOpen, handleDrawerOpen, handleDrawerClose} = userDrawer()

    const {token, setToken, deleteToken} = useToken()

    // const api = new BackendApi()
    // useEffect(() => {
    //     api
    //         .getNotifications(token)
    //         .then((value) => {
    //             if (value.status === 401) {
    //                 deleteToken(false)
    //             }
    //         })
    //         .catch((reason) => {
    //             deleteToken(false)
    //         })
    // })

    return (
        <ThemeProvider theme={muiTheme}>
            <Router>
                <Routes>
                    <Route path={paths.public.forgotPassword}>
                        <ForgotPassword/>
                    </Route>
                    <Route path={paths.public.confirm}>
                        <ConfirmEmail/>
                    </Route>
                    <Route path={paths.public.logIn}>
                        {token ? <Redirect to={paths.root}/> : <LogIn setToken={setToken}/>}
                    </Route>
                    <Route path={paths.public.signUp}>
                        <SignUp/>
                    </Route>

                    {!token ? (
                        <Redirect to={paths.public.logIn}/>
                    ) : (
                        <QueryClientProvider client={queryClient}>
                            <Box sx={{display: 'flex'}}>
                                <DesktopOnly>
                                    <CssBaseline/>
                                </DesktopOnly>
                                <AppBar position="fixed" open={drawerOpen}>
                                    <Toolbar>
                                        <DesktopOnly>
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
                                                edge="start"
                                            >
                                                {drawerOpen ? <ChevronLeftIcon/> : <MenuIcon/>}
                                            </IconButton>
                                        </DesktopOnly>
                                        <MobileOnly>
                                            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                                {data.siteName}
                                            </Typography>
                                        </MobileOnly>
                                        <div className={classes.toolbarButtons}>
                                            <NotificationTooltip/>
                                            <UserDetailsMenu
                                                deleteToken={deleteToken}
                                                toggleDarkTheme={toggleDarkTheme}
                                            />
                                        </div>
                                    </Toolbar>
                                </AppBar>
                                <DesktopOnly>
                                    <Drawer variant="permanent" open={drawerOpen}>
                                        <DrawerHeader>
                                            {drawerOpen && (
                                                <>
                                                    <Typography component="h1" variant="h6" color="inherit">
                                                        {data.siteName}
                                                    </Typography>
                                                    <Typography component="p" variant="subtitle1" color="inherit">
                                                        {data.version}
                                                    </Typography>
                                                </>
                                            )}
                                        </DrawerHeader>
                                        <Divider/>
                                        <MainMenuItems drawerOpen={drawerOpen}/>
                                    </Drawer>
                                </DesktopOnly>
                                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                                    <DrawerHeader/>
                                    <div>
                                        <Routes>
                                            <Route path={paths.userDetails.security}>
                                                <UserDetailsPage/>
                                            </Route>
                                            <Route path={paths.userDetails.general}>
                                                <UserDetailsPage/>
                                            </Route>
                                            <Route path={paths.userDetails.root}>
                                                {/* <Redirect to={paths.userDetails.general} /> */}
                                                <UserDetailsPage/>
                                            </Route>
                                            {features.organisations && (
                                                <Route path={paths.addOrganisation}>
                                                    <AddOrganisation/>
                                                </Route>
                                            )}
                                            {features.organisations && (
                                                <Route path={paths.editOrganisation}>
                                                    <AddOrganisation/>
                                                </Route>
                                            )}
                                            {features.organisations && (
                                                <Route path={paths.organisations}>
                                                    <OrganisationsPage/>
                                                </Route>
                                            )}
                                            {features.accounts && (
                                                <Route path={paths.addAccount}>
                                                    <AddAccount/>
                                                </Route>
                                            )}
                                            {features.accounts && (
                                                <Route path={paths.editAccount}>
                                                    <AddAccount/>
                                                </Route>
                                            )}
                                            {features.accounts && (
                                                <Route path={paths.accounts}>
                                                    <AccountsPage/>
                                                </Route>
                                            )}
                                            {features.wallets && (
                                                <Route path={paths.addContract}>
                                                    <AddContract/>
                                                </Route>
                                            )}
                                            {features.wallets && (
                                                <Route path={paths.editContract}>
                                                    <AddContract/>
                                                </Route>
                                            )}
                                            {features.wallets && (
                                                <Route path={paths.contracts}>
                                                    <ContractsPage/>
                                                </Route>
                                            )}
                                            {features.transactions && (
                                                <Route path={paths.addTransaction}>
                                                    <AddTransaction/>
                                                </Route>
                                            )}
                                            {features.transactions && (
                                                <Route path={paths.editTransaction}>
                                                    <AddTransaction/>
                                                </Route>
                                            )}
                                            {features.transactions && (
                                                <Route path={paths.transactions}>
                                                    <TransactionsPage/>
                                                </Route>
                                            )}
                                            {features.assets && (
                                                <Route path={paths.assets}>
                                                    <AssetsPage/>
                                                </Route>
                                            )}
                                            {features.quotes && (
                                                <Route path={paths.quoteDetails}>
                                                    {/*<QuoteDetailsPage/>*/}
                                                    <Redirect to={paths.dashboard}/>
                                                </Route>
                                            )}
                                            {features.quotes && (
                                                <Route path={paths.quotes}>
                                                    <QuotesPage/>
                                                </Route>
                                            )}
                                            {features.integrations && (
                                                <Route path={paths.integrations}>
                                                    <IntegrationsPage/>
                                                </Route>
                                            )}
                                            {features.trade && (
                                                <Route path={paths.trade}>
                                                    <TerminalPage/>
                                                </Route>
                                            )}
                                            {features.properties && (
                                                <Route path={`${paths.properties}/:propertyId`} exact>
                                                    <PropertyDetailsPage/>
                                                </Route>
                                            )}
                                            {features.properties && (
                                                <Route path={paths.propertyContracts}>
                                                    <PropertyContractsPage/>
                                                </Route>
                                            )}
                                            {features.properties && (
                                                <Route path={paths.properties}>
                                                    <PropertiesPage/>
                                                </Route>
                                            )}
                                            {features.subscriptions && (
                                                <Route path={paths.addSubscription}>
                                                    <AddSubscription/>
                                                </Route>
                                            )}
                                            {features.subscriptions && (
                                                <Route path={paths.subscriptions}>
                                                    <SubscriptionsPage/>
                                                </Route>
                                            )}
                                            {features.notifications && (
                                                <Route path={paths.notifications}>
                                                    <NotificationsPage/>
                                                </Route>
                                            )}
                                            <Route path={paths.miniApps}>
                                                <MiniApps/>
                                            </Route>
                                            <Route path={paths.dashboard}>
                                                <DashboardPage/>
                                            </Route>
                                            <Route path={paths.root}>
                                                <Redirect to={paths.dashboard}/>
                                            </Route>
                                        </Routes>
                                        <Box pt={4}>
                                            <Copyright/>
                                        </Box>
                                    </div>
                                    <MobileBottomNav/>
                                </Box>
                            </Box>
                        </QueryClientProvider>
                    )}
                </Routes>
            </Router>
        </ThemeProvider>
    )
}
