import makeStyles from '@mui/styles/makeStyles'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Theme } from '@mui/material/styles/createTheme'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import HomeIcon from '@mui/icons-material/Home'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import paths from '../../../data/paths.json'
import React, { useEffect, useState } from 'react'
import useToken from '../../../services/useToken'
import BackendApi, { Account, Organisation, Wallet } from '../../../services/backend'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import data from '../../../data/locale/en/data.json'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AssetSelector from '../../../components/AssetSelector'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}))

export default function AddContract() {
  const classes = useStyles()
  const history = useHistory()
  const { token } = useToken()
  const api = new BackendApi()
  // @ts-ignore
  let { id } = useParams()

  const [wallet, setWallet] = useState({
    organisationId: '',
    accountId: '',
    name: '',
    code: '',
    type: 'CASH',
    currency: 'GBP',
    balance: 0,
    id,
  } as Wallet)
  const [walletTypes, setWalletTypes] = useState([] as string[])
  const [accounts, setAccounts] = useState([] as Account[])
  const [organisations, setOrganisations] = useState([] as Organisation[])

  useEffect(() => {
    api.getWalletTypes(token).then((value) => {
      setWalletTypes(value)
    })
    api.getOrganisations(token).then((value) => {
      setOrganisations(value)
    })
    api.getAccounts(token).then((value) => {
      setAccounts(value)
    })
    if (id !== undefined) {
      api.getWalletDetails(id, token).then((value) => {
        console.log('fetched wallet info: ' + JSON.stringify(value))
        setWallet({
          ...wallet,
          organisationId: value.organisationId,
          accountId: value.accountId,
          name: value.name,
          code: value.code,
          type: value.type,
          currency: value.currency,
          balance: value.balance,
          id: value.id,
        })
      })
    }
  }, [])

  function handleFieldChange(name: string, value: string) {
    setWallet({
      ...wallet,
      [name]: value,
    })
    if (name === 'organisationId') {
      if (value === '' || value === undefined) {
        api.getAccounts(token).then((value) => {
          setAccounts(value)
        })
      } else {
        api.getAccountsByOrg(value as string, token).then((value) => {
          setAccounts(value)
        })
      }
    }
  }

  function handleInputChange(
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<String>,
  ) {
    handleFieldChange(event.target.name, event.target.value as string)
  }

  function createWallet(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (id !== undefined) {
      console.log('wallet updated: ' + JSON.stringify(wallet))
      api.updateWallet(wallet, token).then((r) => {
        history.push(paths.contracts)
      })
    } else {
      console.log('wallet created: ' + JSON.stringify(wallet))
      api.createWallet(wallet, token).then((r) => {
        if (r.status === 201) history.push(paths.contracts)
      })
    }
  }

  return (
    <Container component="form" onSubmit={(event) => createWallet(event)} maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              {data.title.home}
            </Link>
            <Link color="inherit" to={paths.contracts}>
              <AccountBalanceWalletIcon className={classes.icon} />
              {data.contracts.title}
            </Link>
            <Typography color="textPrimary">
              {wallet.id !== undefined ? wallet.code : data.title.new}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="organisation-lbl">
              {data.wallets.field.organizationId}
            </InputLabel>
            <Select
              value={wallet.organisationId}
              labelId="organisation-lbl"
              id="organisation"
              name="organisationId"
              label={data.wallets.field.organizationId}
              onChange={(event) => handleInputChange(event)}
            >
              <MenuItem value={''}>{data.selector.all}</MenuItem>
              {organisations.map((value) => (
                <MenuItem value={value.id}>{`${value.name} (${value.code})`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {accounts === undefined || accounts.length === 0 ? (
            <FormControl fullWidth>
              <Button
                fullWidth
                sx={{ height: '55px' }}
                variant="outlined"
                component={Link}
                to={
                  paths.editAccount.replace(':id', paths.new) +
                  '?organisationId=' +
                  wallet.organisationId
                }
              >
                {data.accounts.btn.addAccount}
              </Button>
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <InputLabel id="account-lbl">{data.wallets.field.organizationId}</InputLabel>
              <Select
                value={wallet.accountId}
                labelId="account-lbl"
                id="account"
                name="accountId"
                label={data.wallets.field.organizationId}
                onChange={(event) => handleInputChange(event)}
              >
                {accounts.map((value) => (
                  <MenuItem value={value.id}>{`${value.name} (${value.code})`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={wallet.name}
            onChange={(event) => handleInputChange(event)}
            id="name"
            name="name"
            label={data.wallets.field.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={wallet.code}
            onChange={(event) => handleInputChange(event)}
            id="code"
            name="code"
            label={data.wallets.field.code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AssetSelector
            value={wallet.currency}
            onChange={(newValue: string) => handleFieldChange('currency', newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color={wallet.id !== undefined ? 'secondary' : 'primary'}
          >
            {wallet.id !== undefined ? data.btn.update : data.btn.create}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
