import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import paths from '../data/paths.json'
import { MobileOnly } from './MobileDesktopVisibility'
import AppsIcon from '@mui/icons-material/Apps'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'

export default function MobileBottomNav() {
  const [value, setValue] = useState(paths.dashboard)

  const handleChange = (e: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }
  return (
    <MobileOnly>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation value={value} onChange={handleChange} showLabels>
          <BottomNavigationAction
            icon={<DashboardIcon />}
            label="Dashboard"
            value={paths.dashboard}
            component={Link}
            to={paths.dashboard}
          />
          <BottomNavigationAction
            label="Properties"
            icon={<LocationOnIcon />}
            value={paths.properties}
            component={Link}
            to={paths.properties}
          />
          <BottomNavigationAction
            label="Wallets"
            icon={<AccountBalanceWalletIcon />}
            value={paths.contracts}
            component={Link}
            to={paths.contracts}
          />
          <BottomNavigationAction
            label="Apps"
            icon={<AppsIcon />}
            value={paths.miniApps}
            component={Link}
            to={paths.miniApps}
          />
        </BottomNavigation>
      </Paper>
    </MobileOnly>
  )
}
