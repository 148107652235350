import React, { useEffect, useState } from 'react'
import useToken from '../../../services/useToken'
import BackendApi, { Asset } from '../../../services/backend'
import data from '../../../data/locale/en/data.json'
import { GridColDef } from '@mui/x-data-grid'
import { AlertColor, Avatar, Chip } from '@mui/material'
import FintecyDataGrid from '../../../components/FintecyDataGrid'

function AssetsPage() {
  const { token } = useToken()
  const api = new BackendApi()
  const [rows, setRows] = useState([] as Asset[])
  const [msg, setMsg] = useState(
    data.msg.recordDeleted.replace('$type', data.transactions.title) as string,
  )
  const [alertType, setAlertType] = useState('success' as AlertColor)
  const [open, setOpen] = useState(false)

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') setOpen(false)
  }

  useEffect(() => {
    api
      .getAssets(token)
      .then((value) => {
        setRows(value)
      })
      .catch((reason) => {
        console.log(reason)
      })
  }, [])

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      field: 'flag',
      headerName: data.assets.field.flag,
      description: 'flag for ISO currency',
      type: 'string',
      sortable: true,
      renderCell: (params) =>
        params.row.type === 'FIAT' ? (
          <Avatar
            alt={params.row.code}
            src={`/flags/${params.row.code?.substring(0, 2).toLowerCase()}.webp`}
          />
        ) : (
          <Avatar>{params.row.code.substring(0, 1)}</Avatar>
        ),
    },
    {
      field: 'ticker',
      headerName: data.assets.field.ticker,
      flex: 1,
      editable: false,
    },
    {
      field: 'name',
      headerName: data.assets.field.name,
      flex: 2,
      type: 'string',
      editable: false,
    },
    {
      field: 'symbol',
      headerName: data.assets.field.symbol,
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: (params) => (
        <Chip label={params.row.symbol} color={'default'} />
        // <Avatar>{params.row.symbol}</Avatar>
      ),
    },
    {
      field: 'type',
      headerName: data.assets.field.type,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'fractionDigits',
      headerName: data.assets.field.fractionDigits,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'status',
      headerName: data.assets.field.status,
      flex: 2,
      type: 'string',
      editable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.state}
          color={params.row.state === 'ACTIVE' ? 'success' : 'warning'}
        />
      ),
    },
  ]
  return (
    <FintecyDataGrid
      title={data.assets.title}
      addNewLink={undefined}
      rows={rows}
      columns={columns}
      openSnackbar={open}
      handleSnackbarClose={handleSnackbarClose}
      alertType={alertType}
      msg={msg}
      getRowId={(row: any) => row.id}
    />
  )
}

export default AssetsPage
