import React, { useEffect, useState } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Link } from 'react-router-dom'
import { Account, AccountsApi } from 'fintecy'
import useToken from '../../../services/useToken'
import paths from '../../../data/paths.json'
import BackendApi from '../../../services/backend'
import data from '../../../data/locale/en/data.json'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridColDef } from '@mui/x-data-grid'
import FintecyDataGrid from '../../../components/FintecyDataGrid'
import { AlertColor } from '@mui/material'

function AccountsPage() {
  const { token } = useToken()
  const api = new BackendApi()

  const [rows, setRows] = React.useState([] as Account[])
  const [msg, setMsg] = useState(
    data.msg.recordDeleted.replace('$type', data.transactions.title) as string,
  )
  const [alertType, setAlertType] = useState('success' as AlertColor)
  const [open, setOpen] = useState(false)

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') setOpen(false)
  }
  useEffect(() => {
    const accountsApi = new AccountsApi()
    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    accountsApi
      .getAccounts(options)
      .then((rsp: any) => setRows(rsp.data as Account[]))
      .catch((error: any) => {
        console.log(error)
      })
  }, [token])

  async function deleteAccount(id: string) {
    api.deleteWallet(id, token).then(() => {
      setRows({ ...rows })
    })
  }

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: data.table.actions,
      description: 'supported operations',
      sortable: false,
      renderCell: (params) => (
        <ButtonGroup variant="text" color="primary" aria-label="wallets controls">
          <Tooltip title={data.btn.update}>
            <IconButton
              component={Link}
              to={paths.editAccount.replace(':id', params.row.id || paths.new)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={data.btn.delete}>
            <IconButton onClick={() => deleteAccount(params.row.id || '')}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
    {
      field: 'accountType',
      headerName: data.accounts.field.accountType,
      flex: 1,
      editable: false,
    },
    {
      field: 'ref',
      headerName: data.accounts.field.ref,
      flex: 2,
      type: 'string',
      editable: false,
    },
    {
      field: 'balance',
      headerName: data.accounts.field.balance,
      flex: 2,
      type: 'number',
      editable: false,
    },
    {
      field: 'baseCurrency',
      headerName: data.accounts.field.baseCurrency,
      flex: 1,
      type: 'string',
      editable: false,
    },
  ]
  return (
    <FintecyDataGrid
      title={data.accounts.title}
      addNewLink={paths.addAccount}
      rows={rows}
      columns={columns}
      openSnackbar={open}
      handleSnackbarClose={handleSnackbarClose}
      alertType={alertType}
      msg={msg}
    />
  )
}

export default AccountsPage
