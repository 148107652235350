import { useState, useEffect } from 'react';
import {TransactionService} from "./api/transaction.service";
import {Transaction} from "../model/transaction.type";
import {ACCOUNT_ID} from "../data/consts";

interface ExpenseData {
    categories: string[];
    amounts: number[];
    txs: Transaction[];
}

const testData: Transaction[] = await TransactionService.getTransactions(ACCOUNT_ID);
const useExpenses = (): ExpenseData | null => {
    const [expenseData, setExpenseData] = useState<ExpenseData | null>(null);

    useEffect(() => {
        // Process test data to group expenses by category (excluding positive amounts)
        const expensesByCategory = testData.reduce((acc: Record<string, number>, tx: Transaction) => {
            if (tx.amount.amount < 0) {
                const category = tx.category;
                acc[category] = (acc[category] || 0) + Math.abs(tx.amount.amount);
            }
            return acc;
        }, {} as Record<string, number>);

        // Prepare data for use in charts
        const categories = Object.keys(expensesByCategory) as string[];
        const amounts = Object.values(expensesByCategory) as number[];

        setExpenseData({ categories, amounts, txs: testData });
    }, []);

    return expenseData;
};

export default useExpenses;
